import React, {useEffect, useState} from 'react';
import {Box, Chip, CircularProgress, Grid} from '@mui/material';
import {useMapContext} from "/@/context/mapContext";
import {useThemeContext} from "/@/context/themeContext";
import {useMapData} from "/@/context/useMapData";
import {PollutantType} from "/@/constants/chartContants";
import AirQualityChart from "/@/components/graphs/AirQualityChart";

const AirQualityGraphs = () => {
    const {isMobile} = useThemeContext()
    const {
        chosenBorough,
        chosenGraphSensor,
        chosenDateRange,
        airQualityData,
        chosenGuideline
    } = useMapContext();

    const {getAverageReadingsByBorough, getSensorReadingsByDateRange} = useMapData();
    const [isLoadingChartData, setIsLoadingChartData] = useState<boolean>(true);


    useEffect(() => {
        const fetchInitialData = async () => {
            await getAverageReadingsByBorough();
        }
        fetchInitialData().then(() => setIsLoadingChartData(false))
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            chosenGraphSensor ? await getSensorReadingsByDateRange() : await getAverageReadingsByBorough();
        };
        fetchData();
    }, [chosenBorough, chosenGraphSensor, chosenDateRange, getSensorReadingsByDateRange, getAverageReadingsByBorough]);
    const AirQualityGraphsDesktop = () => {
        return (
            <>
                <AirQualityChart pollutant="PM2.5" airQualityData={airQualityData} isMapPage={true}/>
                <AirQualityChart pollutant="PM10" airQualityData={airQualityData} isMapPage={true}/>
                <AirQualityChart pollutant="NO2" airQualityData={airQualityData} isMapPage={true}/>
            </>
        );
    };

    const AirQualityGraphsMobile = () => {
        const [selectedPollutant, setSelectedPollutant] = useState<PollutantType>('PM2.5');
        return (
            <>
                <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1}>
                    {(['PM2.5', 'PM10', 'NO2'] as PollutantType[]).map((pollutant) => (
                        <Chip
                            key={pollutant}
                            label={pollutant}
                            onClick={() => setSelectedPollutant(pollutant)}
                            color={selectedPollutant === pollutant ? 'primary' : 'default'}
                        />
                    ))}
                </Box>
                <AirQualityChart pollutant={selectedPollutant} airQualityData={airQualityData} isMapPage={true}/>
            </>
        );
    };

    if (isLoadingChartData) {
        return (
            <CircularProgress/>
        )
    }

    return (
        <Grid container>
            {isMobile ? (
                <AirQualityGraphsMobile/>
            ) : (
                <AirQualityGraphsDesktop/>
            )}
        </Grid>
    );
};

export default AirQualityGraphs;