import React from "react";
import {
    Autocomplete,
    Box,
    FormControl,
    Grid, InputLabel, MenuItem,
    Select,
    TextField, ToggleButton,
    ToggleButtonGroup,
    Tooltip as ToolTipMui,
    Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMapContext} from "/@/context/mapContext";
import {Borough, useOnboardingContext} from "/@/context/onboardingContext";
import selectArrowIcon from "/@/assets/select-arrow-icon.svg";
import {styled} from "@mui/material/styles";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, {Dayjs} from 'dayjs';
import {PickersShortcutsItem} from '@mui/x-date-pickers/PickersShortcuts';
import {DateRange, SingleInputDateRangeField, MultiInputDateRangeField} from "@mui/x-date-pickers-pro";
import InteractiveMapCarousel from "/@/components/map/InteractiveMapCarousel";
import 'dayjs/locale/en-gb';
import {useThemeContext} from "/@/context/themeContext";
import {
    INTERVAL_OPTION_TO_LABEL,
    INTERVAL_OPTIONS,
    METRIC_OPTION_TO_LABEL,
    METRIC_OPTIONS
} from "/@/constants/mapConstants";
import {Metric, Interval} from "/@/types/mapTypes";
import {filterIntervalOptions} from "/@/utils/mapUtils";


const StyledToggleButton = styled(ToggleButton)(({theme, selected}) => ({
    width: '100%', // Full width of its parent container
    '&.Mui-selected, &.Mui-selected:hover': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.light,
    },
}));

const predefinedBoroughs: { description: string; borough: string }[] = [
    {description: 'Average Hackney Data', borough: 'Hackney'},
    {description: 'Average City of London Data', borough: 'City of London'},
    {description: 'Average Newham Data', borough: 'Newham'},
    {description: 'Average Tower Hamlets Data', borough: 'Tower Hamlets'}
];
const predefinedBoroughValues = predefinedBoroughs.map((borough) => borough.borough);


export const CustomDropDownIcon = (
    props: React.ImgHTMLAttributes<HTMLImageElement>
) => <img src={selectArrowIcon} alt="Select arrow" {...props} />;


interface GraphProps {
    toggleDrawer: (open: boolean) => void;
}

const GraphSettings: React.FC<GraphProps> = ({toggleDrawer}) => {
    const {t} = useTranslation();
    const {isMobile} = useThemeContext();
    const {
        chosenDateRange,
        setChosenDateRange,
        setChosenGuideline,
        chosenGuideline,
        chosenGraphSensor,
        chosenBorough,
        handleBoroughChange,
        setChosenGraphInterval,
        chosenGraphInterval,
        chosenGraphMetric,
        setChosenGraphMetric,
        handleGraphDateRangeChange
    } = useMapContext();

    let dropdownValue = '';

    if (chosenGraphSensor) {
        dropdownValue = chosenGraphSensor.name;
    } else if (chosenBorough && predefinedBoroughValues.includes(chosenBorough)) {
        dropdownValue = chosenBorough;
    }
    const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
        {
            label: 'Today',
            getValue: () => {
                const today = dayjs();
                return [today.startOf('day'), today.endOf('day')];
            },
        },
        {
            label: 'Last 7 Days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(7, 'day'), today];
            },
        },
        {
            label: 'Last 30 Days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(30, 'day'), today];
            },
        },
        {
            label: 'Last 90 Days',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(90, 'day'), today];
            },
        },
        {
            label: 'Last Year',
            getValue: () => {
                const today = dayjs();
                return [today.subtract(1, 'year'), today];
            },
        },
        // { label: 'Reset', getValue: () => [null, null] },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <Grid container sx={{mb: 2}} id={'air-quality-settings'}>
                <Grid item xs={12} sx={{mb: 2}} id='tour-interactive-map-section'>
                    <InteractiveMapCarousel toggleDrawer={toggleDrawer}/>
                </Grid>
                <Grid container spacing={2} sx={{mb: 2}}>
                    <Grid item xs={6} id='tour-location-picker'>
                        <Box height={'20px'}/>
                        <FormControl fullWidth>
                            <Autocomplete
                                id="borough-select-label"
                                options={predefinedBoroughValues}
                                value={dropdownValue}
                                onChange={(event, newValue) => {
                                    handleBoroughChange(newValue as Borough);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('viewing_data_for')} variant="outlined"/>
                                )}
                            />
                        </FormControl>
                        {/*<FormControl fullWidth sx={{mt: 2.5}}>*/}
                        {/*    <InputLabel id="demo-simple-select-label">{t('viewing_data_for')}</InputLabel>*/}
                        {/*    <Select*/}
                        {/*        id="borough-select-label"*/}
                        {/*        value={chosenGraphSensor ? chosenGraphSensor.name : chosenBorough}*/}
                        {/*        onChange={(e) => handleBoroughChange(e.target.value as Borough)}*/}
                        {/*    >*/}
                        {/*        {predefinedBoroughValues.map((option) => (*/}
                        {/*            <MenuItem key={option} value={option}>*/}
                        {/*                {option}*/}
                        {/*            </MenuItem>*/}
                        {/*        ))}*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                    </Grid>
                    <Grid item xs={6} id='tour-guidelines-switch'>
                        <Typography component={'p'} variant={'body2'}>{isMobile? t('select_air_quality_guideline_mobile'): t('select_air_quality_guideline')}</Typography>
                        <ToggleButtonGroup
                            value={chosenGuideline}
                            exclusive
                            onChange={(event, newGuideline) => {
                                if (newGuideline !== null) {
                                    setChosenGuideline(newGuideline);
                                }
                            }}
                            aria-label="guideline"
                            fullWidth
                            sx={{height: '56px'}}
                        >
                            <ToolTipMui title={t('defra_toggle')} arrow enterTouchDelay={0}>
                                <StyledToggleButton value="DEFRA" aria-label="DEFRA"
                                                    selected={chosenGuideline === 'DEFRA'}
                                                    id='tour-guidelines-switch-defra'>
                                    DEFRA
                                </StyledToggleButton>
                            </ToolTipMui>
                            <ToolTipMui title={t('who_toggle')} arrow enterTouchDelay={0}>
                                <StyledToggleButton value="WHO" aria-label="WHO" selected={chosenGuideline === 'WHO'}
                                                    id='tour-guidelines-switch-who'>
                                    WHO
                                </StyledToggleButton>
                            </ToolTipMui>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent={"space-between"} id='tour-date-range-picker'>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Typography component={'p'} variant={'body2'} sx={{mb: 1}}>
                            {t("average_period")}
                        </Typography>
                        {isMobile ?
                            <DateRangePicker
                                calendars={1}
                                name="allowedRange"
                                localeText={{start: t('start_date'), end: t('end_date')}}
                                value={chosenDateRange}
                                slots={{ field: SingleInputDateRangeField }}
                                onChange={(newValue) => handleGraphDateRangeChange(newValue)}
                                minDate={dayjs('2023-03-17')}
                                maxDate={dayjs()}
                                sx={{width: '100%', pr: 1}}
                            />
                            : <DateRangePicker
                            slotProps={{
                                shortcuts: {
                                    items: shortcutsItems,
                                },
                            }}
                            // calendars={1}
                            slots={{ field: SingleInputDateRangeField }}
                            name="allowedRange"
                            localeText={{start: t('start_date'), end: t('end_date')}}
                            value={chosenDateRange}
                            onChange={(newValue) => handleGraphDateRangeChange(newValue)}
                            minDate={dayjs('2023-03-17')}
                            maxDate={dayjs()}
                            sx={{width: '100%', pr: 1}}
                        />}
                    </Grid>
                    {!isMobile && <><Grid xs={3}>
                        <FormControl fullWidth sx={{pl: 1, mt: 3.5}}>
                            <InputLabel id="demo-simple-select-label">{t('interval')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={chosenGraphInterval}
                                label={t('interval')}
                                onChange={(e) => setChosenGraphInterval(e.target.value as Interval)}
                            >
                                {filterIntervalOptions(chosenDateRange!).map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {INTERVAL_OPTION_TO_LABEL[option]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={3}>
                        <FormControl fullWidth sx={{pl: 1, mt: 3.5}}>
                            <InputLabel id="demo-simple-select-label">{t('metric')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={chosenGraphMetric}
                                label={t('metric')}
                                onChange={(e) => setChosenGraphMetric(e.target.value as Metric)}
                            >
                                {METRIC_OPTIONS.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {METRIC_OPTION_TO_LABEL[option]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid></>}
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default GraphSettings;
