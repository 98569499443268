import React from 'react';
import {Drawer, IconButton as MuiIconButton, Stack, Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GraphSettings from "./GraphSettings";
import AirQualityGraphs from "./AirQualityGraphs";
import {styled} from "@mui/material/styles";

const DrawerHeader = styled(Box)(({theme}) => ({
    backgroundColor: '#cee7ef',
    position: 'relative',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    visibility: 'visible',
    right: 0,
    left: 0,
    marginBottom: '30px',
    zIndex: 9999,
}));

const DrawerContent = styled(Box)(({theme}) => ({
    width: 'auto',
    height: '70vh',
    overflowY: 'scroll',
}));

const IconButton = styled(MuiIconButton)(({theme}) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.25),
    zIndex: 1300,
}));

const StackedContent = styled(Stack)(({theme}) => ({
    padding: theme.spacing(1),
}));

interface MobileDrawerProps {
    open: boolean;
    toggleDrawer: (open: boolean) => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({open, toggleDrawer}) => {
    return (
        <Drawer
            anchor={'bottom'}
            open={open}
            onClose={() => toggleDrawer(false)}
        >
            <DrawerHeader>
                <IconButton
                    onClick={() => toggleDrawer(false)}
                >
                    <CloseIcon/>
                </IconButton>
            </DrawerHeader>
            <DrawerContent>
                <StackedContent>
                    <GraphSettings toggleDrawer={toggleDrawer}/>
                    <AirQualityGraphs/>
                </StackedContent>
            </DrawerContent>
        </Drawer>
    );
};

export default MobileDrawer;